// Here you can add other styles
.bodybackg {
    background-color: #e4e5e6;
}
.bg-lgray {
    background-color: #efefef;
}

.app-header .navbar-brand {
    padding-left: 1rem;
    padding-right: 1.5rem;
}

a .hover-red:hover {
    color: $danger;
}

.img-avatar {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    opacity: 0.5;
    filter: alpha(opacity=80);
}
.img-avatar:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}
.table-striped tbody tr:nth-of-type(odd):hover {
    background-color: rgba(0, 0, 0, 0.07);
}

.icon-wms {
    width: 10rem;
    -webkit-border-radius: 0.25rem;
    -moz-border-radius: 0.25rem;
    border-radius: 0.25rem;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    opacity: 0.8;
    filter: alpha(opacity=80);
}
.icon-wms:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}
.img-wms {
    width: 90%;
    margin-left: 5%;
    float: left;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
}

.floatright {
    margin: auto;
    margin-right: 1%;
    float: right;
}

.users-form .form-group{
    margin-bottom: 0;
}
.delete_hover {
    color: #333;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.delete_hover:hover {
    color: $danger;
}
.edit_hover {
    color: #333;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.edit_hover:hover {
    color: $primary;
}
