// Variable overrides
$primary:                            #199355;
$dark:                               #333333;
$info:                               #25cfa9;
$yelow: #f1ee1e;

$gray-300:                           #c8d3d0; // lighten($gray-base, 70%);
$gray-400:                           #acbcb6; // lighten($gray-base, 60%);
$gray-500:                           #8fa69d; // lighten($gray-base, 50%);
$gray-600:                           #6a7e74; // lighten($gray-base, 40%);
$gray-700:                           #5c7368; // lighten($gray-base, 30%);
$gray-800:                           #2f3a37;

$gray-900:                           #333333; //DARK components

$sidebar-bg:                         #333333;

$body-bg:                            #e4e5e6;
$gray-body:                          #e4e5e6;
$breadcrumb-bg:                      #eeeeee;
$table-dark-bg:                      #444444;

// Custom Styles

$navbar-brand-width:                  250px;

$sidebar-width:                       250px;

$sidebar-nav-title-padding-y:         1rem;
$sidebar-nav-title-padding-x:         1.5rem;
$sidebar-nav-link-padding-y:          1rem;
$sidebar-nav-link-padding-x:          1.5rem;
